import React, { useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Navigate, useSearchParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchLeanIdentity } from "../../stores/actions/bfm.actions.type";
import { errorMessage, successMessage } from "../../utilities/notification";
import Loader from "../../components/Loader";
const { PUBLIC_URL } = process.env;

const FundManagementIdentity = (props) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const identity = useSelector((state) => state?.bfm?.identity ?? null);
  useEffect(() => {
    try {
      const callback = (data) => {
        if (data.success) {
          // successMessage("Fetched identity successfully!");
        }
      };
      dispatch(
        fetchLeanIdentity({
          entityId: entityId,
          callback,
        })
      );
    } catch (e) {
      errorMessage("Failed to fetch data!");
    }
  }, []);
  const accountId = searchParams.get("accountId");
  const entityId = searchParams.get("entityId");
  return (
    <AuthContext.Consumer>
      {(auth) => {
        if (!auth) {
          return <Navigate to="/login" />;
        } else {
          return (
            <div className="id-content">
              {props.visible ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="col-12">
                    <h6 className="id-h">Identity</h6>
                    <hr />
                  </div>
                  <div className="col-lg-12 d-flex justify-content-between align-items-center border-inner">
                    <span className="left-title">Customer Name</span>
                    <span className="right-title">
                      {identity?.full_legal_name ?? ""}
                    </span>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-between align-items-center border-inner">
                    <span className="left-title">Age</span>
                    <span className="right-title">
                      {identity?.age ?? "N/A"}
                    </span>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-between align-items-center border-inner">
                    <span className="left-title">Date Of Birth</span>
                    <span className="right-title">
                      {identity?.regional_data?.data?.verified_claims[0]
                        ?.verification?.claims?.birth_date ?? "N/A"}
                    </span>
                  </div>

                  <div className="col-lg-12 d-flex justify-content-between align-items-center border-inner">
                    <span className="left-title">Sex</span>
                    <span className="right-title">
                      {identity?.sex ?? "N/A"}
                    </span>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-between align-items-center border-inner">
                    <span className="left-title">Email</span>
                    <span className="right-title">
                      {identity?.email_address ?? "N/A"}
                    </span>
                  </div>

                  <div className="col-lg-12 d-flex justify-content-between align-items-center border-inner">
                    <span className="left-title">Phone Number</span>
                    <span className="right-title">
                      {identity?.mobile_number ?? identity?.phone ?? "N/A"}
                    </span>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-between align-items-center border-inner">
                    <span className="left-title">Address Line 1</span>
                    <span className="right-title">
                      {identity?.address.address_line ?? "N/A"}
                    </span>
                  </div>

                  <div className="col-lg-12 d-flex justify-content-between align-items-center border-inner">
                    <span className="left-title">Address Line 2</span>
                    <span className="right-title">
                      {identity?.address?.building_number ?? "N/A"}
                    </span>
                  </div>

                  <div className="col-lg-12 d-flex justify-content-between align-items-center border-inner">
                    <span className="left-title">Address Line 3</span>
                    <span className="right-title">{`${
                      (identity?.address?.street_name ?? "") + ","
                    } ${(identity?.address?.town_name ?? "") + ","} ${
                      identity?.address?.country ?? +","
                    } ${(identity?.address?.post_code ?? "") + "."}`}</span>
                  </div>
                </div>
              )}
            </div>
            // </div>
            // </div>
          );
        }
      }}
    </AuthContext.Consumer>
  );
};

const mapStateToProps = (state) => ({
  visible: state.app.visible,
});

export default connect(mapStateToProps)(FundManagementIdentity);
