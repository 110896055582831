import { all, takeLatest, put } from "redux-saga/effects";
import { cardsSlice } from "../slices/cards";
import {
  FETCH_CARDS,
  ORDER_PHYSICAL_CARD,
  ORDER_VIRTUAL_CARD,
  GET_CARD_PREFERENCES,
  SET_CARD_PREFERENCES,
  SET_CARD_STATUS,
  GET_BALANCE,
  GET_ACCOUNT,
  FUND_TRANSFER
} from "../actions/card.actions.types";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* fetchCards({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: `/paytabs/all-cards?id=${data.id}&idType=${data.idType}&page=${
      data.page
    }&size=${data.size}&cardStatus=${data.cardStatus}&search=${
      data.search || ""
    }`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(cardsSlice.actions.cardsList(result.data));
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* orderPhysicalCard({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/issue-physical-card`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* orderVirtualCard({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/issue-virtual-card`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* getCardPreferences({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/card/get-preferences`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(cardsSlice.actions.preferences(result.data));
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* setCardPreferences({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/card/set-preferences`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback(result);
    }
  }
}

function* setCardStatus({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/card/status`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback(result);
    }
  }
}

function* getBalance({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/balance`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: false,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* getAccount({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/account/get`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* depositFund({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/transfer-fund`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* Cards() {
  yield all([takeLatest(FETCH_CARDS, fetchCards)]);
  yield all([takeLatest(ORDER_PHYSICAL_CARD, orderPhysicalCard)]);
  yield all([takeLatest(ORDER_VIRTUAL_CARD, orderVirtualCard)]);
  yield all([takeLatest(GET_CARD_PREFERENCES, getCardPreferences)]);
  yield all([takeLatest(SET_CARD_PREFERENCES, setCardPreferences)]);
  yield all([takeLatest(SET_CARD_STATUS, setCardStatus)]);
  yield all([takeLatest(GET_ACCOUNT, getAccount)]);
  yield all([takeLatest(GET_BALANCE, getBalance)]);
  yield all([takeLatest(FUND_TRANSFER, depositFund)]);
}
export default Cards;
