import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { CustomMenu, CustomToggle } from "../../components/UI/LanguageSwitcher";
import { logout } from "../../stores/actions/user.actions.types";
import { errorMessage, successMessage } from "../../utilities/notification";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { getProfilePicture } from "../../stores/actions/setting.actions.types";
import { setSocketNotification } from "../../stores/actions/notifications.actions";
import {socket} from "../../utilities/socket";


const { PUBLIC_URL } = process.env;
const EVENT_NAME = "new-notification-to-company";

const AuthHeader = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const user = useSelector((state) => state.user?.userDetails);

  useEffect(() => {
    if(user?.token) {
      const socketConnection = socket(user.token);
      function handler(data) {
        props.setSocketNotification(data);
        setNotifications((prev) =>[ ...prev, data ]);
      }
      socketConnection.on(EVENT_NAME, handler);

      socketConnection.on("connect", () => {
        console.log("Connected to WebSocket server");
      });

      return () => {
        socketConnection.off(EVENT_NAME);
      };
    }
  }, [user]);

  const handleLogout = () => {
    try {
      const callback = (data) => {
        if (data.success) {
          successMessage(t("msg-logout-success"));
          setProfileDropdown(false);
          navigate("/login");
        }
      };
      props.logout({
        callback,
      });
    } catch (error) {
      errorMessage(error.message);
    }
  };

  const handleShowNotification = () => {
    setNotifications([]);
    navigate("/notifications");
  };

  const handleToggleClass = () => {
    document.body.classList.toggle("showSidebar");
  };
  return (
    <React.Fragment>
      <div className="dashContentHeader d-flex justify-content-between align-items-center">
        <h5 className="dash-heading">{props?.pageTitle || ""}</h5>
        <div className="menuOverlay" onClick={handleToggleClass}></div>
        <div className="menuToggle" onClick={handleToggleClass}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="d-flex align-items-center nav-wrap">
          <div
            className="notifyTopBar position-relative"
            onClick={handleShowNotification}
            style={{ cursor: "pointer" }}
          >
            <svg
              width={16}
              height={18}
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.17398 6.56166C2.17295 5.82876 2.317 5.1029 2.59781 4.42593C2.87862 3.74896 3.29065 3.13426 3.81013 2.61726C4.32962 2.10027 4.9463 1.69121 5.62461 1.41366C6.30293 1.13611 7.02947 0.995556 7.76236 1.00011C10.8205 1.02284 13.2666 3.56478 13.2666 6.63148V7.17963C13.2666 9.94624 13.8455 11.5517 14.3553 12.4291C14.4102 12.5229 14.4394 12.6295 14.44 12.7381C14.4406 12.8468 14.4125 12.9537 14.3586 13.048C14.3047 13.1423 14.2268 13.2208 14.1329 13.2754C14.039 13.3301 13.9323 13.359 13.8237 13.3593H1.61631C1.50764 13.359 1.40096 13.3301 1.30705 13.2754C1.21313 13.2208 1.13529 13.1423 1.08139 13.0479C1.02748 12.9536 0.999409 12.8467 1.00001 12.738C1.00061 12.6293 1.02986 12.5227 1.0848 12.429C1.59488 11.5515 2.17397 9.9461 2.17397 7.17963L2.17398 6.56166Z"
                stroke="#2B4778"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.24805 13.3594V13.9773C5.24805 14.6329 5.50848 15.2617 5.97204 15.7252C6.43561 16.1888 7.06434 16.4492 7.71992 16.4492C8.3755 16.4492 9.00423 16.1888 9.4678 15.7252C9.93136 15.2617 10.1918 14.6329 10.1918 13.9773V13.3594"
                stroke="#2B4778"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {notifications.length ? (
              <span className="notify-dot">{notifications.length}</span>
            ) : (
              ""
            )}
          </div>
          <div className="dropdown inflowDropdown">
            <button
              className="btn dropdown-toggle company-name bg-trans"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.3618 18.3235V10.8082C19.3618 4.85174 14.5115 0.00146484 8.55502 0.00146484C8.08701 0.00146484 7.70408 0.384398 7.70408 0.852409V6.97906H1.63142C1.58606 6.975 1.53966 6.97476 1.49235 6.97906L0.811605 7.0216C0.343594 7.0216 -0.0393391 7.44707 0.00323941 7.87254C0.0399975 8.27696 0.331054 8.58556 0.683987 8.66208V18.3136C0.296796 18.393 0.00323941 18.7375 0.00323941 19.1473C0.00323941 19.6153 0.386172 19.9982 0.854183 19.9982H1.53493H8.55506H18.5109H19.1491C19.6171 19.9982 20 19.6153 20 19.1473C20 18.7527 19.7277 18.4186 19.3618 18.3235ZM17.6599 10.8082V18.2964H9.40597V7.83C9.40597 7.73778 9.39089 7.64891 9.36343 7.56555V1.70331C14.001 2.12879 17.6599 6.04304 17.6599 10.8082ZM7.70412 18.2964H5.87461V14.8501C5.87461 14.3821 5.49168 13.9992 5.02367 13.9992C4.55566 13.9992 4.17272 14.3821 4.17272 14.8501V18.2964H2.34326C2.34326 18.212 2.34326 8.68091 2.34326 8.68091H7.70408V18.2964H7.70412Z"
                    fill="#253858"
                  />
                  <path
                    d="M13.5331 8.72334C14.0011 8.72334 14.384 8.3404 14.384 7.87239V7.57458C14.384 7.10657 14.0011 6.72363 13.5331 6.72363C13.0651 6.72363 12.6821 7.10657 12.6821 7.57458V7.87239C12.6821 8.3404 13.0651 8.72334 13.5331 8.72334Z"
                    fill="#253858"
                  />
                  <path
                    d="M13.5331 12.6379C14.0011 12.6379 14.384 12.2975 14.384 11.7869V11.4891C14.384 11.0211 14.0011 10.6382 13.5331 10.6382C13.0651 10.6382 12.6821 11.0211 12.6821 11.4891V11.7869C12.6821 12.255 13.0651 12.6379 13.5331 12.6379Z"
                    fill="#253858"
                  />
                  <path
                    d="M13.5331 16.5944C14.0011 16.5944 14.384 16.2541 14.384 15.7435V15.4457C14.384 14.9777 14.0011 14.5947 13.5331 14.5947C13.0651 14.5947 12.6821 14.9777 12.6821 15.4457V15.7435C12.6821 16.2115 13.0651 16.5944 13.5331 16.5944Z"
                    fill="#253858"
                  />
                </svg>
              </span>
              <span className="name" data-toggle="tooltip" title={user?.company?.company_name}>{user?.company?.company_name}</span>
            </button>
          </div>
          <div className="dropdown inflowUserDropdown">
            <Dropdown>
              <Dropdown.Toggle
                noCaret
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                <span className="header-profile-pic ratio ratio-1x1 rounded-circle overflow-hidden" data-toggle="tooltip" title= {`${user?.user?.c} ${user?.user?.last_name}`}>
                  <img
                    src={
                      props?.userDetails?.profilePicture
                        ? props?.userDetails?.profilePicture
                        : `${PUBLIC_URL}/images/default-pic.png`
                    }
                    alt="Profile"
                    className="card-img-top img-cover"
                  />
                </span>
                <span className="user-info">{`${user?.user?.first_name} ${user?.user?.last_name}`}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item>
                  <li className="dropdown-item" onClick={()=>navigate("/setting")}>
                    <span className="dropdown-item p-0 cursor-pointer">
                      {t("profile")}
                    </span>
                  </li>
                </Dropdown.Item>
                <Dropdown.Item>
                  <li className="dropdown-item" onClick={handleLogout}>
                    <span className="dropdown-item p-0 cursor-pointer">
                      {t("logout")}
                    </span>
                  </li>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <LanguageSwitcher />
        </div>
      </div>

      {/*<nav className="navbar navbar-expand-md fixed-top navbar-dark navtop">
      <div className="container">
        <Link className="navbar-brand" to="/">
          In flow
        </Link>
        <button
          onClick={toggleMenu}
          className="navbar-toggler p-0 border-0"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`navbar-collapse offcanvas-collapse ${
            isToggle ? "open" : ""
          }`}
          id="navbarsExampleDefault"
        >
          <ul style={{ marginLeft: "auto !important" }} className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to={ROUTE.PROFILE}>
                My Profile
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={(e) => {
                  dispatch(logout());
                }}
                className="nav-link"
                to="/"
              >
                Signout
              </Link>
            </li>
          </ul>
        </div>
      </div>
      </nav>*/}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.user.userDetails,
  pageTitle: state.app.pageTitle,
});

export default connect(mapStateToProps, { logout, getProfilePicture, setSocketNotification })(
  AuthHeader
);
