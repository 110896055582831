import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Navigate, useSearchParams } from "react-router-dom";
import { errorMessage } from "../../utilities/notification";
import {
  fetchRegularSpendCategories,
  fetchCashFlowAnalysis,
} from "../../stores/actions/bfm.actions.type";
import { useDispatch, useSelector } from "react-redux";
import SpendCategoriesAccordionItem from "../../components/UI/SpendCategoriesAccordionItem";
import Loader from "../../components/Loader";
import InsightsChart from "../../components/UI/InsightsChart";

import SelectChartPeriodDropdown from "../../components/UI/SelectChartPeriodDropdown";
import { groupBy, reduce, map, chain, mapObject, pluck } from "underscore";
function FundManageMentInsights() {
  const dispatch = useDispatch();

  const regularSpendCategories = useSelector(
    (state) => state?.bfm?.regularSpendCategories || {}
  );

  const uid = useSelector(
    (state) => state?.bfm?.regularSpendCategoriesReqUid || ""
  );
  const visible = useSelector((state) => state?.app.visible);
  const cashFlowAnalysisData = useSelector(
    (state) => state?.bfm?.cashFlowData?.monthWiseTotals || {}
  );
  const cashFlowTotals = useSelector(
    (state) => state?.bfm?.cashFlowData?.totals || {}
  );
  // const [tranxs, setTranx] = useState([]);
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("accountId");
  const entityId = searchParams.get("entityId");

  const [flow, setFlow] = useState();

  useEffect(() => {
    if (!Object.keys(cashFlowTotals).length) {
      setFlow({ inflow: null, outflow: null });
    } else {
      setFlow({
        inflow: cashFlowTotals.inflow.amount / cashFlowTotals.inflow.count,
        outflow: cashFlowTotals.outflow.amount / cashFlowTotals.outflow.count,
      });
    }
    return () => setFlow({ inflow: 0, outflow: 0 });
  }, [cashFlowAnalysisData]);

  useEffect(() => {
    try {
      if (uid !== `${accountId}-${entityId}`) {
        const callback = (data) => {
          if (data.success) {
          }
        };

        dispatch(
          fetchRegularSpendCategories({
            accountId,
            entityId,
            period: "p_1_M",
            callback,
          })
        );
      }
    } catch (error) {
      errorMessage(error.reason);
    }
  }, [accountId, entityId]);
  // return null

  return (
    <AuthContext.Consumer>
      {(auth) => {
        if (!auth) {
          return <Navigate to="/login" />;
        }
        return (
          <>
            {visible ? (
              <Loader />
            ) : (
              <div className="insight-inner">
                <div className="row">
                  <div className="col-12">
                    <h6 className="cash-title">Cash flow Analysis of last </h6>
                    <div className="row">
                      <div className="col">
                        <SelectChartPeriodDropdown
                          accountId={accountId}
                          entityId={entityId}
                          fetchRegularSpendCategories={
                            fetchRegularSpendCategories
                          }
                        />
                      </div>
                    </div>

                    <div className="chart">
                      <InsightsChart data={cashFlowAnalysisData} />
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="income-details col-12 d-flex align-items-center justify-content-between">
                      <span className="left-title">Mean Income</span>
                      <span className="right-title">
                        {flow?.inflow?.toFixed(2) || "..."}
                      </span>
                    </div>
                    <div className="income-details col-12 d-flex align-items-center justify-content-between">
                      <span className="left-title">Mean Expenses</span>
                      <span className="right-title">
                        {flow?.outflow?.toFixed(2) || "..."}
                      </span>
                    </div>
                    <div className="income-details col-12 d-flex align-items-center justify-content-between">
                      <span className="left-title">Mean Disposable Income</span>
                      <span className="right-title">
                        {flow?.inflow
                          ? (flow?.inflow - flow?.outflow)?.toFixed(2)
                          : "..."}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <h6 className="cash-title mt-5">Regular Spend Categories</h6>
                  <hr />
                </div>
                <div className="row spend-cat">
                  <div className="accordion" id="accordionExample">
                    {Object.keys(regularSpendCategories)
                      .sort()
                      .map((el, i) => (
                        <SpendCategoriesAccordionItem
                          key={`spend-cat-accr-${i}`}
                          item={regularSpendCategories[el]}
                          label={el}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}
          </>
        );
      }}
    </AuthContext.Consumer>
  );
}

export default FundManageMentInsights;
